<template>
  <v-autocomplete
    :label="title"
    placeholder="Seleccionar un elemento"
    :loading="loading"
    :items="items"
    item-text="description"
    item-value="description"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    ref="auto"
  ></v-autocomplete>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  props: {
    reset: { type: Boolean, required: false },
    value: { type: String },
    reload: [Boolean],
    name: { type: String, required: true },
    title: { type: String, required: true },
  },
  data: () => ({
    loading: false,
    items: [],
    onlyRequired: [rules.required],
  }),
  methods: {
    getCatalog() {
      this.loading = true;
      let uri = "/api/catalogs/catalog/?name=" + this.name;
      requests.get(uri).then((res) => {
        if (res.status == 200) {
          this.items = res.data;
        } else {
          console.log(res);
        }
      });
      this.loading = false;
    },
  },
  watch: {
    reset: function () {
      this.$refs.auto.reset();
    },
    reload: function () {
      this.getCatalog();
    },
    title: function (newVal) {
      console.log(newVal);
    },
    name: function (newVal) {
      console.log(newVal);
    },
  },
  mounted() {
    this.getCatalog();
  },
};
</script>
